/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to right, rgba(248, 249, 250, 0.8), rgba(233, 236, 239, 0.8)),
              url(/static/media/bg.01d93119.jpg) no-repeat center center fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Form Container */
.form-container {
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
}

/* Header (h2) Styles */
.form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #4CAF50; /* Vibrant green color */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 10px;
}

/* Add a decorative underline */
.form-container h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #4CAF50;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

/* Input Fields */
.form-container input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

/* Button Styles */
.form-container button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form-container button:hover {
  background-color: #0056b3;
}

/* Error Message */
.form-container .error {
  color: #dc3545;
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

/* Dashboard Container */
.dashboard-container {
  max-width: 9000px; /* Increase the maximum width */
  padding: 30px; /* Increase padding for more space inside */
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly larger shadow */
  background: white;
  width: 40vw;
}

/* Dashboard Header (h2) Styles */
.dashboard-container h2 {
  font-size: 32px; /* Increase font size for better impact */
  color: #4CAF50; /* Vibrant green color */
  text-align: center;
  margin-bottom: 30px; /* Increase margin for more spacing */
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  position: relative;
  padding-bottom: 15px; /* Increase padding for more space below */
}

/* Add a decorative underline */
.dashboard-container h2::after {
  content: '';
  display: block;
  width: 100px; /* Increase width of the underline */
  height: 4px;
  background-color: #4CAF50; /* Vibrant green color */
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-container {
    padding: 15px;
  }

  .form-container input,
  .form-container button {
    font-size: 14px;
  }

  .dashboard-container {
    padding: 20px;
  }

  .dashboard-container h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
h6 {
  width: 100%;
  padding: 1px;
  background-color:#4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  }


